"use client";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { createContext, useEffect, useState } from "react";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "",
    person_profiles: "always", // or 'always' to create profiles for anonymous users as well,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
    mask_all_text: false,
  });
}
type Alert = {
  severity: AlertColor;
  message: string;
};

export const AlertContext = createContext({});

export function Providers({ children }: { children: React.ReactNode }) {
  const [is_snackbar_open, toggle_snackbar] = useState(false);

  const [alerts, set__alerts] = useState<Alert[]>([]);

  const [current_alert, set__current_alert] = useState<Alert | null>(null);

  useEffect(() => {
    if (alerts.length && !current_alert) {
      // Set a new snack when we don't have an active one
      set__current_alert({ ...alerts[0] });
      set__alerts((prev) => prev.slice(1));
      toggle_snackbar(true);
    } else if (alerts.length && current_alert && is_snackbar_open) {
      // Close an active snack when a new one is added
      toggle_snackbar(false);
    }
  }, [alerts, current_alert, is_snackbar_open]);

  const handle_exitec = () => {
    set__current_alert(null);
  };

  const alert = ({
    severity,
    message,
  }: {
    severity: AlertColor;
    message: string;
  }) => {
    set__alerts((prev) => [...prev, { severity, message }]);
  };

  return (
    <PostHogProvider client={posthog}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AlertContext.Provider value={{ alert }}>
          {children}
        </AlertContext.Provider>

        <Snackbar
          open={is_snackbar_open}
          onClose={() => toggle_snackbar(false)}
          autoHideDuration={6000}
          TransitionProps={{ onExited: handle_exitec }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => toggle_snackbar(false)}
            severity={current_alert?.severity}
            sx={{ width: "100%" }}
          >
            {current_alert?.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </PostHogProvider>
  );
}
