import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { initializeApp } from "firebase/app";

const config = {
  apiKey: "AIzaSyC5dYxI3HybMaLNfYvpFtcBthcs14DlNeg",
  authDomain: "actionyourpotential-52daf.firebaseapp.com",
  projectId: "actionyourpotential-52daf",
  storageBucket: "actionyourpotential-52daf.appspot.com",
  messagingSenderId: "556812242729",
  appId: "1:556812242729:web:cdbd5248213dc7fd5a3138",
};

export const app = initializeApp(config);

const headers = {
  "Content-Type": "application/json",
} as any;

export let { NEXT_PUBLIC_HOST: host } = process.env;
// if (!host) host = "http://localhost:3000";
if (!host) host = "https://ayp-api.azurewebsites.net";

let current_user: User | null = null;

const wait_for_user = (): Promise<User | null> => {
  return new Promise((resolve) => {
    if (current_user !== null) resolve(current_user);
    else {
      const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
        unsubscribe();
        current_user = user;
        resolve(user);
      });
    }
  });
};

// Improved token retrieval function
export const get_token = async (): Promise<string | null> => {
  const user = await wait_for_user();

  if (user) {
    try {
      const token = await user.getIdToken(true); // Force token refresh
      if (token) headers["Authorization"] = `Bearer ${token}`;

      return token;
    } catch (error) {
      console.error("Error getting token:", error);
      return null;
    }
  }

  return null;
};

export const get = async (url: string, params?: any) => {
  try {
    if (!headers["Authorization"]) await get_token();

    const data = await fetch(`${host}/${url}`, {
      method: "GET",
      headers,
    });

    return await data.json();
  } catch (error) {
    console.log(error);
  }
};

export const post = async (url: string, body: any) => {
  try {
    if (!headers["Authorization"]) await get_token();

    const data = await fetch(`${host}/${url}`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    return await data.json();
  } catch (error) {
    console.log(error);
  }
};

export const put = async (url: string, body: any) => {
  try {
    if (!headers["Authorization"]) await get_token();

    const data = await fetch(`${host}/${url}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    });

    return await data.json();
  } catch (error) {
    console.log(error);
  }
};

export const remove = async (url: string) => {
  try {
    if (!headers["Authorization"]) await get_token();

    const data = await fetch(`${host}/${url}`, {
      method: "DELETE",
      headers,
    });

    return await data.json();
  } catch (error) {
    console.log(error);
  }
};
