"use client";

import { app } from "@/utils/fetch";
import { Box, Button, TextField, Typography } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useContext, useState } from "react";
import { AlertContext } from "../providers";

const Login = () => {
  const router = useRouter();

  let { alert } = useContext<any>(AlertContext);
  const [email, set__email] = useState("");
  const [password, set__password] = useState("");

  const submit = async () => {
    try {
      const credentials = await signInWithEmailAndPassword(
        getAuth(app),
        email,
        password
      );

      // check claims - must be admin
      const token = await credentials.user.getIdTokenResult();

      if (
        token?.claims?.role !== "ADMIN" &&
        token?.claims?.role !== "MENTOR" &&
        token?.claims?.role !== "SCHOOL"
      ) {
        await getAuth().signOut();

        alert({
          severity: "error",
          message: "You are not authorised to access this page",
        });
      } else {
        await fetch("/api/login", {
          headers: { Authorization: `Bearer ${token.token}` },
        });

        alert({
          severity: "success",
          message: "Successfully logged in",
        });

        router.push("/");
        window.location.reload();
      }
    } catch (e: any) {
      console.log(e);

      if (e.code === "auth/user-disabled") {
        alert({
          severity: "error",
          message: "This account has been disabled",
        });
      } else if (
        e.code === "auth/wrong-password" ||
        e.code === "auth/user-not-found"
      ) {
        alert({
          severity: "error",
          message: "Invalid email or password",
        });
      } else {
        alert({
          severity: "error",
          message: "Error logging in",
        });
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
        marginTop: 50,
      }}
    >
      <Image
        src="/ayp_logo.png"
        alt="Action Your Potential Logo"
        width={170}
        height={170}
      />

      <Typography
        variant="h1"
        color="textPrimary"
        noWrap
        component="div"
        fontSize={(theme) => theme.typography.h4.fontSize}
        fontWeight={(theme) => theme.typography.fontWeightBold}
        style={{ marginBottom: 10 }}
      >
        Action Your Potential
      </Typography>

      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: 5,
        }}
        width={300}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          label="Email address"
          variant="outlined"
          type="email"
          InputLabelProps={{ shrink: true }}
          value={email}
          onChange={(e) => set__email(e.target.value)}
        />

        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          type="password"
          InputLabelProps={{ shrink: true }}
          value={password}
          onChange={(e) => set__password(e.target.value)}
        />

        <Button fullWidth variant="contained" disableElevation onClick={submit}>
          Login
        </Button>
      </Box>
    </div>
  );
};

export default Login;
